import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';

export default helper(function renderHeaderTitle([names]: [{ text: string; special: boolean; type: string }]) {
	if (names.special) {
		const symbols: { [key: string]: string } = {
			plus: '+',
			minus: '-',
			multiply: 'x',
			divide: '÷',
		};

		const symbol = symbols[names.type];
		if (symbol) {
			return htmlSafe(`${symbol} <span class='w-11 inline-block'>&nbsp;</span> ${names.text}`);
		}
	}
	return names.text;
});
