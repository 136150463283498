import type { CattleFeedRationFilterDTO, CattleFeedRationSortByDTO, CattleFeedRation } from '../graphql-types';

export interface RationCreateEditFormError {
	key: keyof RationCreateEditFormData;
	message: string;
}

export interface RationCreateEditFormData {
	name: string;
	description: string;
}
export interface CattleFeedRationCreateResponse {
	createCattleFeedRation?: {
		id: string;
		name: string;
		description: string;
	};
}

export interface CattleFeedRationUpdateResponse {
	updateCattleFeedRation?: {
		id: string;
		name: string;
		description: string;
	};
}

export interface CreateRationData {
	businessId: string;
	name: string;
	description: string;
}

export interface GetRationsVariables {
	where?: CattleFeedRationFilterDTO;
	orderBy?: CattleFeedRationSortByDTO;
}

export interface GetRationsResponse {
	CattleFeedRations: CattleFeedRation[];
}

export interface UpdateRationData {
	name: string;
	description: string;
}

export interface RationsIndexModel {
	businessId: string;
	rationsQuery: {
		data: {
			CattleFeedRations: CattleFeedRation[];
		};
	};
}
