/**
 * Interface representing aggregated cattle lot data
 */
export interface AggregateCattleLot {
	activeEndMonthStartDate: string;
	sum: {
		forecastedSalesRevenueInUsd: number;
		cattleLotTotalExpensesInUsd: number;
		purchasePriceInUsd: number;
	};
}

/**
 * Interface for the projected P&L data response
 */
export interface GetCattleProjectedPlData {
	AggregateCattleLots: AggregateCattleLot[];
}
