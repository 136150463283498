import type Owner from '@ember/owner';
import FuturePosition from 'vault-client/models/future-position';
import OptionPosition from 'vault-client/models/option-position';
import Position from 'vault-client/models/position';
import SwapPosition from 'vault-client/models/swap-position';
import SwaptionPosition from 'vault-client/models/swaption-position';
import { CurrentPosition, Future, Option, Swap, Swaption } from 'vault-client/types/graphql-types';

type InstrumentWithTypename = (Future | Option | Swap | Swaption) & {
	__typename: 'Future' | 'Option' | 'Swap' | 'Swaption';
};

function itemsFn(rows: CurrentPosition[], owner: Owner): Position[] {
	return rows
		.filter((row): row is CurrentPosition & { Instrument: InstrumentWithTypename } =>
			row.Instrument !== null &&
			row.Instrument !== undefined &&
			'__typename' in row.Instrument
		)
		.map((row) => {
			switch (row.Instrument.__typename) {
				case 'Future':
					return new FuturePosition(owner, row);
				case 'Option':
					return new OptionPosition(owner, row);
				case 'Swap':
					return new SwapPosition(owner, row);
				case 'Swaption':
					return new SwaptionPosition(owner, row);
				default:
					return undefined;
			}
		})
		.filter((position): position is FuturePosition | OptionPosition | SwapPosition | SwaptionPosition =>
			position !== undefined
		);
}

export {
	itemsFn,
};
