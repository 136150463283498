import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';
import {
	Query,
	Query_FieldArgs,
	Query_CropFieldLedgerCategoriesArgs,
	CropFieldLedgerCategory,
	FieldLedgerEntryPerHarvestYear,
} from 'vault-client/types/graphql-types';
import BusinessesBusinessRoute from '../business';
import { ModelFrom } from 'vault-client/utils/type-utils';

const GET_FIELD = gql`
	query Field($id: String!, $where: FieldLedgerEntryPerHarvestYearFilterDTO) {
		Field(id: $id) {
			id
			name
			acres
			FieldLedgerEntriesPerHarvestYear(where: $where) {
				id
				harvestYear
				cropFieldLedgerType
				harvestYearStartDate
				flatValueInUsd
				perAcreValueInUsd
				description
				CropFieldLedgerCategory {
					id
					name
					description
					type
					businessId
				}
			}
		}
	}
`;

const GET_FIELD_LEDGER_CATEGORIES = gql`
	query CropFieldLedgerCategories($ledgerCategoryWhere: CropFieldLedgerCategoryFilterDTO) {
		CropFieldLedgerCategories(where: $ledgerCategoryWhere) {
			id
			name
			description
			type
			businessId
		}
	}
`;

type Field = Query['Field'] & {
	FieldLedgerEntriesPerHarvestYear: FieldLedgerEntryPerHarvestYear[];
};

type FieldQuery = Query['Field'] & {
	Field: Field;
};

interface QueryFieldParams {
	field_id: string;
	startDate: string;
	endDate: string;
}

export interface LedgerCategoryQuery {
	CropFieldLedgerCategories: CropFieldLedgerCategory[];
}

interface LedgerCategoryArgs {
	ledgerCategoryWhere: Query_CropFieldLedgerCategoriesArgs['where'];
}
export default class BusinessesBusinessFieldRoute extends Route {
	@tracked variables = { id: '', where: {} };
	@tracked ledgerCategoryWhere = { businessId: { equals: '' } };
	getField = useQuery<FieldQuery, Query_FieldArgs>(this, () => [GET_FIELD, { variables: this.variables }]);
	getLedgerCategories = useQuery<LedgerCategoryQuery, LedgerCategoryArgs>(this, () => [
		GET_FIELD_LEDGER_CATEGORIES,
		{ variables: { ledgerCategoryWhere: this.ledgerCategoryWhere } },
	]);

	queryParams = {
		startDate: { refreshModel: true },
		endDate: { refreshModel: true },
	};

	async model(params: QueryFieldParams) {
		const { businessId } = this.modelFor('businesses.business') as ModelFrom<BusinessesBusinessRoute>;

		this.variables = {
			id: params.field_id,
			where: { harvestYearStartDate: { gte: params.startDate, lte: params.endDate } },
		};
		this.ledgerCategoryWhere = {
			businessId: {
				equals: businessId,
			},
		};

		await this.getField.promise;
		await this.getLedgerCategories.promise;

		return { field: this.getField, businessId: businessId, ledgerCategories: this.getLedgerCategories };
	}
}
