import { gql, useMutation } from 'glimmer-apollo';
import {
	Mutation,
	Mutation_createFieldArgs,
	Mutation_updateFieldArgs,
	Mutation_createCropFieldLedgerCategoryArgs,
	TypeOfCropFieldLedger,
	Mutation_deleteManyFieldLedgerEntriesPerHarvestYearArgs,
	Mutation_setManyFieldLedgerEntriesPerHarvestYearArgs,
} from 'vault-client/types/graphql-types';

export type CreateFieldData = { error: string; name: string; acres: string; businessId: string };
export type UpdateFieldData = { error: string; name: string; acres: string };
export type CreateRevExpCategoryData = {
	error: string;
	name: string;
	description: string;
	businessId: string;
	type: TypeOfCropFieldLedger;
};
const CREATE_GRAIN_FIELD = gql`
	mutation CreateField($data: FieldCreateDTO!) {
		createField(data: $data) {
			id
			acres
			name
		}
	}
`;

const UPDATE_GRAIN_FIELD = gql`
	mutation UpdateField($data: FieldUpdateDTO!, $id: String!) {
		updateField(data: $data, id: $id) {
			id
			acres
			name
		}
	}
`;

const CREATE_FIELD_REVENUE_EXPENSE_CATEGORY = gql`
	mutation CreateCropFieldLedgerCategory($data: CropFieldLedgerCategoryCreateDTO!) {
		createCropFieldLedgerCategory(data: $data) {
			id
		}
	}
`;

const SET_MANY_FIELD_LEDGER_ENTRIES_PER_HARVEST_YEAR = gql`
	mutation SetManyFieldLedgerEntriesPerHarvestYear($data: [FieldLedgerEntryPerHarvestYearCreateDTO!]!) {
		setManyFieldLedgerEntriesPerHarvestYear(data: $data) {
			id
		}
	}
`;

const DELETE_MANY_FIELD_LEDGER_ENTRIES_PER_HARVEST_YEAR = gql`
	mutation DeleteManyFieldLedgerEntriesPerHarvestYear($ids: [String!]!) {
		deleteManyFieldLedgerEntriesPerHarvestYear(ids: $ids) {
			id
		}
	}
`;

async function createField(context: object, variables: Mutation_createFieldArgs) {
	const mutation = useMutation<{ createField: Mutation['createField'] }, Mutation_createFieldArgs>(context, () => [
		CREATE_GRAIN_FIELD,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error(`Failed to create field: ${variables}`);
			},

			update: (cache) => {
				cache.evict({ fieldName: 'Field' });
				cache.evict({ fieldName: 'Fields' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
	return mutation.data;
}

async function updateField(context: object, variables: Mutation_updateFieldArgs) {
	const mutation = useMutation<{ updateField: Mutation['updateField'] }, Mutation_updateFieldArgs>(context, () => [
		UPDATE_GRAIN_FIELD,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error(`Failed to update field: ${variables}`);
			},

			update: (cache) => {
				cache.evict({ fieldName: 'Field' });
				cache.evict({ fieldName: 'Fields' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
	return mutation.data;
}

async function createRevExpCategory(context: object, variables: Mutation_createCropFieldLedgerCategoryArgs) {
	const mutation = useMutation<{ createCategory: Mutation['createCropFieldLedgerCategory'] }, Mutation_createCropFieldLedgerCategoryArgs>(
		context,
		() => [
			CREATE_FIELD_REVENUE_EXPENSE_CATEGORY,
			{
				onError: (error): void => {
					console.error(error);
					throw new Error(`Failed to create new category: ${variables}`);
				},

				update: (cache) => {
					cache.evict({ fieldName: 'Field' });
					cache.evict({ fieldName: 'Fields' });
					cache.evict({ fieldName: 'CropFieldLedgerCategories' });
					cache.gc();
				},
			},
		],
	);

	await mutation.mutate(variables);
	return mutation.data;
}

async function setManyFieldLedgerEntriesPerHarvestYear(context: object, variables: Mutation_setManyFieldLedgerEntriesPerHarvestYearArgs) {
	const mutation = useMutation<
		{ setManyFieldLedgerEntriesPerHarvestYear: Mutation['setManyFieldLedgerEntriesPerHarvestYear'] },
		Mutation_setManyFieldLedgerEntriesPerHarvestYearArgs
	>(context, () => [
		SET_MANY_FIELD_LEDGER_ENTRIES_PER_HARVEST_YEAR,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error(`Failed to update field ledger entries: ${variables}`);
			},

			update: (cache) => {
				cache.evict({ fieldName: 'Field' });
				cache.evict({ fieldName: 'Fields' });
				cache.evict({ fieldName: 'CropFieldLedgerCategories' });
				cache.evict({ fieldName: 'FieldLedgerEntriesPerHarvestYear' });
				cache.gc();
			},
		},
	]);
	await mutation.mutate(variables);
	return mutation.data;
}

async function deleteManyFieldLedgerEntriesPerHarvestYear(
	context: object,
	variables: Mutation_deleteManyFieldLedgerEntriesPerHarvestYearArgs,
) {
	const mutation = useMutation<
		{ deleteManyFieldLedgerEntriesPerHarvestYear: Mutation['deleteManyFieldLedgerEntriesPerHarvestYear'] },
		Mutation_deleteManyFieldLedgerEntriesPerHarvestYearArgs
	>(context, () => [
		DELETE_MANY_FIELD_LEDGER_ENTRIES_PER_HARVEST_YEAR,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error(`Failed to delete field ledger entries: ${variables.ids}`);
			},

			update: (cache) => {
				cache.evict({ fieldName: 'Field' });
				cache.evict({ fieldName: 'Fields' });
				cache.evict({ fieldName: 'CropFieldLedgerCategories' });
				cache.evict({ fieldName: 'FieldLedgerEntriesPerHarvestYear' });
				cache.gc();
			},
		},
	]);
	await mutation.mutate(variables);
	return mutation.data;
}

function parseFieldData(data: CreateFieldData | UpdateFieldData) {
	if ('businessId' in data) {
		return {
			name: data.name.trim(),
			acres: typeof data.acres == 'string' ? parseFloat(data.acres.trim()) : data.acres,
			businessId: data.businessId,
		};
	} else {
		return {
			name: data.name.trim(),
			acres: typeof data.acres == 'string' ? parseFloat(data.acres.trim()) : data.acres,
		};
	}
}

function parseFieldCategoryLedgerData(data: CreateRevExpCategoryData) {
	return {
		name: data.name.trim(),
		description: data.description.trim(),
		businessId: data.businessId,
		type: data.type,
	};
}

export {
	createField,
	parseFieldData,
	updateField,
	createRevExpCategory,
	setManyFieldLedgerEntriesPerHarvestYear,
	deleteManyFieldLedgerEntriesPerHarvestYear,
	parseFieldCategoryLedgerData,
};
