import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';

interface VaultTableCellInputArgs {
	datum: { [key: string]: any };
	conditionalStyleFn?: (datum: { [key: string]: any }, ...args: any[]) => string;
	onInput?: (datum: { [key: string]: any }, event: Event) => void;
	style?: string;
	type?: string;
	valuePath: string;
}

export default class VaultTableCellInput extends Component<VaultTableCellInputArgs> {
	noop = () => {};

	get style(): any {
		return htmlSafe((this.args.conditionalStyleFn ? this.args.conditionalStyleFn(this.args.datum) : '') + ' ' + (this.args.style ?? ''));
	}

	get onInput() {
		return this.args.onInput ?? this.noop;
	}
}
