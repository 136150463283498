import Route from '@ember/routing/route';
import Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import BusinessesBusinessFeedOverviewController from 'vault-client/controllers/businesses/business/feed-overview';
import FeedOverviewController from 'vault-client/controllers/feed/overview';
import BusinessesBusinessFeedOverviewRoute from 'vault-client/routes/businesses/business/feed-overview';
import MarketDataService from 'vault-client/services/market-data';
import { CustomerEntity, Query } from 'vault-client/types/graphql-types';
import { ModelFrom } from 'vault-client/utils/type-utils';

interface ModelParams {
	startDate: string;
	endDate: string;
}

const GET_FEED_OVERVIEW = gql`
	query FeedOverview($scopeId: String!, $startDate: String!, $endDate: String!, $futuresStartDate: String!, $futuresEndDate: String!) {
		FeedIngredientConsumedAndPurchasedVolumes(
			where: { startDate: $startDate, endDate: $endDate }
			orderBy: { monthStartDate: Asc }
			scopeId: $scopeId
			limit: 2000
		) {
			id
			feedIngredientId
			monthStartDate
			purchasedInTons
			totalPurchasedCostInUsd
			forecastedConsumptionInTons
			FeedIngredient {
				id
				name
				cmePercentageBasis
				cmeUsdBasis
				updatedAt
				flatPricePerTon
				FeedIngredientPrices {
					id
					date
					price
					pricingMethodology
				}
				FeedCategory {
					id
					name
					updatedAt
					defaultFlatPricePerTon
					defaultCmePercentageBasis
					defaultCmeUsdBasis
					HedgeProduct {
						id
						slug
					}
				}
				FeedIngredientUsages(orderBy: { updatedAt: Desc }) {
					id
					updatedAt
				}
				LivestockGroupFeedUsages(orderBy: { updatedAt: Desc }) {
					id
					updatedAt
				}
				FeedTransactions(orderBy: { updatedAt: Desc }) {
					id
					updatedAt
				}
			}
		}
		Futures(
			where: {
				Product: { slug: { in: ["grain-corn", "grain-soybean-meal"] } }
				isStandardContractSize: { equals: true }
				displayExpiresAt: { gte: $futuresStartDate, lte: $futuresEndDate }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			name
			barchartSymbol
			displayExpiresAt
			SymbolGroup {
				id
				displayFactor
				fractionDigits
			}
			Product {
				id
				slug
				StandardProductLotSpecification {
					id
					pointValue
					lotSize
				}
			}
		}
		AggregateAllocatedForecastedHedgedAndCappedVolumes(
			calc: { sum: { naturallyShortHedged: true } }
			groupBy: { Product: { slug: true }, date: true, instrumentType: true, optionType: true }
			where: {
				date: { gte: $startDate, lte: $endDate }
				hedgeType: { equals: Brokerage }
				instrumentType: { in: [Future, Option] }
				Product: { slug: { in: ["grain-corn", "grain-soybean-meal"] } }
				OR: [{ optionType: { equals: Call } }, { optionType: { equals: null } }]
				entityId: { equals: $scopeId }
			}
			orderBy: { date: Asc }
		) {
			date
			Product {
				slug
			}
			instrumentType
			optionType
			sum {
				naturallyShortHedged
			}
		}
		CurrentAllocationPositions(
			scopeId: $scopeId
			where: {
				effectiveHedgeDate: { gte: $startDate, lte: $endDate }
				Product: { slug: { in: ["grain-corn", "grain-soybean-meal"] } }
				OR: [{ optionType: { equals: Call } }, { optionType: { equals: null } }]
			}
			orderBy: { effectiveHedgeDate: Asc }
		) {
			id
			effectiveHedgeDate
			instrumentType
			contractQuantity
			lifetimeWeightedAveragePrice
			optionType
			effectiveHedgeDate
			Product {
				id
				slug
				StandardProductLotSpecification {
					id
					pointValue
				}
			}
			Instrument {
				type
				SymbolGroup {
					id
					fractionDigits
					displayFactor
				}
				... on Future {
					id
					displayExpiresAt
				}
				... on Swap {
					id
					displayExpiresAt
				}
				... on Option {
					id
					strike
					optionType
					displayExpiresAt
				}
				... on Swaption {
					id
					optionType
					displayExpiresAt
					strike
				}
			}
		}
	}
`;

interface FeedOverviewQueryArgs {
	scopeId: string;
	startDate: string;
	endDate: string;
	futuresStartDate: string;
	futuresEndDate: string;
}

interface FeedOverviewQuery {
	FeedIngredientConsumedAndPurchasedVolumes: Query['FeedIngredientConsumedAndPurchasedVolumes'];
	AggregateAllocatedForecastedHedgedAndCappedVolumes: Query['AggregateAllocatedForecastedHedgedAndCappedVolumes'];
	Futures: Query['Futures'];
	CurrentAllocationPositions: Query['CurrentAllocationPositions'];
}

export default class FeedOverviewRoute extends Route {
	templateName = 'feed/overview/index';

	queryParams = {
		startDate: { refreshModel: true },
		endDate: { refreshModel: true },
	};

	@service declare marketData: MarketDataService;

	@tracked scopeId = '';
	@tracked customer: CustomerEntity | null = null;
	@tracked variables: FeedOverviewQueryArgs = {
		startDate: '',
		endDate: '',
		futuresStartDate: '',
		futuresEndDate: '',
		scopeId: '',
	};

	getFeedOverview = useQuery<FeedOverviewQuery, FeedOverviewQueryArgs>(this, () => [
		GET_FEED_OVERVIEW,
		{
			variables: this.variables,
		},
	]);

	async model(params: ModelParams) {
		this.variables = {
			startDate: params.startDate,
			endDate: params.endDate,
			futuresStartDate: DateTime.fromISO(params.startDate).minus({ quarter: 1 }).toISODate(),
			futuresEndDate: DateTime.fromISO(params.endDate).plus({ quarter: 1 }).toISODate(),
			scopeId: this.scopeId,
		};

		await this.getFeedOverview.promise;

		return {
			getFeedOverview: this.getFeedOverview,
			scopeId: this.scopeId,
			Customer: this.customer,
		};
	}

	setupController(
		controller: FeedOverviewController,
		model: ModelFrom<BusinessesBusinessFeedOverviewRoute>,
		transition: Transition<unknown>,
	): void {
		super.setupController(controller, model, transition);

		const registeredFutures =
			model.getFeedOverview.data?.Futures.reduce<string[]>((acc, future) => {
				const barchartSymbol = future.barchartSymbol;

				if (barchartSymbol) {
					this.marketData.register(barchartSymbol);
					acc.push(barchartSymbol);
				}

				return acc;
			}, []) ?? [];

		controller.registeredFutures = registeredFutures;

		controller.showCornTable = window.localStorage.getItem(`feed-overview-hedgedCornChart.${model.scopeId}`) === 'true';
		controller.showSoybeanMealTable = window.localStorage.getItem(`feed-overview-hedgedSoybeanMealChart.${model.scopeId}`) === 'true';
	}

	resetController(controller: BusinessesBusinessFeedOverviewController, isExiting: boolean) {
		if (isExiting) {
			controller.closeSidePanel();
		}

		controller.registeredFutures.forEach((symbol) => {
			this.marketData.unregister(symbol);
		});

		controller.registeredFutures = [];
	}
}
