// @ts-ignore
import ApolloService from 'ember-apollo-client/services/apollo';
import { createHttpLink } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { setContext } from '@apollo/client/link/context';
// @ts-ignore
import { possibleTypes } from 'vault-client/graphql/possibleTypes';
import { promiseToObservable } from 'vault-client/utils/apollo-utils';

export default class OverriddenApolloService extends ApolloService {
	@service router;
	@service auth;
	@service errorHandler;

	getHeaders = () => {
		const headers = {};

		if (!this.auth.accessToken) {
			return headers;
		}

		headers.Authorization = `Bearer ${this.auth.accessToken}`;

		return headers;
	};

	clientOptions() {
		return {
			link: this.link(),
			cache: this.cache(),
			defaultOptions: {
				watchQuery: {
					fetchPolicy: 'cache-and-network',
				},
			},
		};
	}

	cache() {
		return new InMemoryCache({ possibleTypes: possibleTypes });
	}

	link() {
		// @ts-ignore
		const { apiURL, requestCredentials } = this.options;
		const linkOptions = { uri: apiURL };

		if (isPresent(requestCredentials)) {
			linkOptions.credentials = requestCredentials;
		}

		const authLink = setContext(async () => {
			let headers = this.getHeaders();

			if (!headers.Authorization) {
				await this.auth.refreshTokens();
				headers = this.getHeaders();
			}

			if (!headers.Authorization) {
				// This should have been prevented above
				throw new Error('Authorization token could not be fetched for apollo-client');
			}

			return { headers };
		});

		// Afterware
		const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
			if (graphQLErrors) {
				graphQLErrors.forEach(({ message, locations, path, extensions }) => {
					if (extensions.code === 'UNAUTHENTICATED' || extensions.code === 'invalid-jwt' || message.includes('Access denied')) {
						return promiseToObservable(
							this.auth.refreshTokens().then(() =>
								operation.setContext({
									headers: this.getHeaders(),
								}),
							),
						).flatMap(() => forward(operation));
					} else {
						this.errorHandler.handleError(
							`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${extensions.code}`,
							false,
						);
					}
				});
			}

			if (networkError) {
				const statusCode = networkError.statusCode;
				console.error(`[Network error]: ${statusCode} ${networkError}`);
				if (statusCode && statusCode === 401) {
					return promiseToObservable(
						this.auth.refreshTokens().then(() =>
							operation.setContext({
								headers: this.getHeaders(),
							}),
						),
					).flatMap(() => forward(operation));
				} else {
					this.errorHandler.handleError(networkError, false);
				}
			}
		});

		return errorLink.concat(authLink.concat(createHttpLink(linkOptions)));
	}
}
