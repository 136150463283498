import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Vault::UiForm as |form|>\n\t{{#if @data.error}}\n\t\t<span class='text-sm text-brand-error-50'>\n\t\t\t{{@data.error}}\n\t\t</span>\n\t{{/if}}\n\t<form.group as |group|>\n\t\t<group.label>Field Name&nbsp;<sup>&#42;</sup></group.label>\n\t\t<group.input\n\t\t\t@type='text'\n\t\t\t@value={{@data.name}}\n\t\t\tclass='w-1/4'\n\t\t\tdata-required='true'\n\t\t\tdata-message='A field name is required.'\n\t\t\taria-required='true'\n\t\t/>\n\t</form.group>\n\t<form.group as |group|>\n\t\t<group.label class='mt-4'>Acres&nbsp;<sup>&#42;</sup></group.label>\n\t\t<group.input\n\t\t\t@type='number'\n\t\t\tstep='any'\n\t\t\t@value={{@data.acres}}\n\t\t\tclass='w-1/4'\n\t\t\tdata-required='true'\n\t\t\tdata-message='Acres amount is required and must be a non negative number.'\n\t\t\taria-required='true'\n\t\t\tdata-non-negative-number={{@data.acres}}\n\t\t/>\n\t</form.group>\n</Vault::UiForm>", {"contents":"<Vault::UiForm as |form|>\n\t{{#if @data.error}}\n\t\t<span class='text-sm text-brand-error-50'>\n\t\t\t{{@data.error}}\n\t\t</span>\n\t{{/if}}\n\t<form.group as |group|>\n\t\t<group.label>Field Name&nbsp;<sup>&#42;</sup></group.label>\n\t\t<group.input\n\t\t\t@type='text'\n\t\t\t@value={{@data.name}}\n\t\t\tclass='w-1/4'\n\t\t\tdata-required='true'\n\t\t\tdata-message='A field name is required.'\n\t\t\taria-required='true'\n\t\t/>\n\t</form.group>\n\t<form.group as |group|>\n\t\t<group.label class='mt-4'>Acres&nbsp;<sup>&#42;</sup></group.label>\n\t\t<group.input\n\t\t\t@type='number'\n\t\t\tstep='any'\n\t\t\t@value={{@data.acres}}\n\t\t\tclass='w-1/4'\n\t\t\tdata-required='true'\n\t\t\tdata-message='Acres amount is required and must be a non negative number.'\n\t\t\taria-required='true'\n\t\t\tdata-non-negative-number={{@data.acres}}\n\t\t/>\n\t</form.group>\n</Vault::UiForm>","moduleName":"vault-client/components/create-field-form.hbs","parseOptions":{"srcName":"vault-client/components/create-field-form.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export interface CreateFieldFormSignature {
	Args: {
		businessId: string;
		closeSidePanel: () => void;
		submitAction: (variables: any) => void;
	};
}

export default class CreateFieldFormComponent extends Component<CreateFieldFormSignature> {
	@tracked acres = '';
	@tracked name = '';

	@action
	submit() {
		const variables = {
			data: {
				acres: this.acres,
				name: this.name,
				businessId: this.args.businessId,
			},
		};

		this.args.submitAction(variables);
	}
}
