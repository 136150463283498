import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @field}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Remove Field</confirmation.title>\n\t\t<confirmation.body>Are you sure that you want to remove this field?\n\t\t\t<br />\n\t\t\t<span class='font-sans-semibold'>\n\t\t\t\t{{@field.name}}\n\t\t\t</span>\n\t\t\t<br />\n\t\t\tYou cannot undo this action\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' @disabled={{this.submitting}} {{on 'click' this.removeField}}>Remove</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"{{#if @field}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Remove Field</confirmation.title>\n\t\t<confirmation.body>Are you sure that you want to remove this field?\n\t\t\t<br />\n\t\t\t<span class='font-sans-semibold'>\n\t\t\t\t{{@field.name}}\n\t\t\t</span>\n\t\t\t<br />\n\t\t\tYou cannot undo this action\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' @disabled={{this.submitting}} {{on 'click' this.removeField}}>Remove</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/remove-field-confirmation.hbs","parseOptions":{"srcName":"vault-client/components/remove-field-confirmation.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { gql, useMutation } from 'glimmer-apollo';
import { Mutation } from 'vault-client/types/graphql-types';

export interface RemoveFieldConfirmationSignature {
	// The arguments accepted by the component
	Args: {
		closeModalFn: () => void;
		field: {
			id: string;
		};
		showError: () => void;
		afterDelete: () => void;
	};
	// Any blocks yielded by the component
	Blocks: {
		default: [];
	};
	// The element to which `...attributes` is applied in the component template
	Element: null;
}

const REMOVE_FIELD = gql`
	mutation deleteField($id: String!) {
		deleteField(id: $id) {
			id
		}
	}
`;

export default class RemoveFieldConfirmationComponent extends Component<RemoveFieldConfirmationSignature> {
	removeFieldMutation = useMutation<Mutation['deleteField'], { id: string }>(this, () => [
		REMOVE_FIELD,
		{
			update: (cache) => {
				cache.evict({ fieldName: 'Field' });
				cache.evict({ fieldName: 'Fields' });
				cache.evict({ fieldName: 'FieldsCount' });
				cache.gc();
			},
			onComplete: (): void => {
				this.closeConfirmation();
			},
			onError: (error): void => {
				console.error('Error while attempting to remove field', error.message);
				this.args.showError();
				this.closeConfirmation();
			},
		},
	]);

	@action
	async removeField() {
		await this.removeFieldMutation.mutate({ id: this.args.field.id });
		this.args.afterDelete();
	}

	@action
	closeConfirmation() {
		this.args.closeModalFn();
	}
}
