import { MonthSymbol } from 'vault-client/types/graphql-types';

export function monthSymbolToMonthNumber(monthSymbol: MonthSymbol) {
	const map = [
		MonthSymbol.January,
		MonthSymbol.February,
		MonthSymbol.March,
		MonthSymbol.April,
		MonthSymbol.May,
		MonthSymbol.June,
		MonthSymbol.July,
		MonthSymbol.August,
		MonthSymbol.September,
		MonthSymbol.October,
		MonthSymbol.November,
		MonthSymbol.December,
	];

	return map.findIndex((month) => month === monthSymbol);
}
