import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { inject as service } from '@ember/service';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import {
	LedgerCategory,
	BusinessEntityRole,
	LedgerMilkCheckCategory,
	CustomerEntity,
	TypeOfLedgerCalculation,
	LedgerRevenueCategory,
	LedgerExpenseCategory,
} from 'vault-client/types/graphql-types';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessBusinessSettingsRoute from 'vault-client/routes/businesses/business/business-settings';
import { v5 as uuid } from 'uuid';
import { camelize } from '@ember/string';
import { generateFullName } from 'vault-client/utils/general';

interface User {
	email: string | null;
	firstName?: string | null | undefined;
	id: string;
	lastName?: string | null | undefined;
}

interface Member {
	User?: User | null | undefined;
	createdAt: string;
	id: string;
	permission: string;
	userEmail?: string | null;
	canReadBrokerage: boolean;
	canWriteBrokerage: boolean;
	canReadEntity: boolean;
	canWriteEntity: boolean;
	canReadFeedAndCropPlan: boolean;
	canWriteFeedAndCropPlan: boolean;
	canReadInsurance: boolean;
	canWriteInsurance: boolean;
	canReadOperations: boolean;
	canWriteOperations: boolean;
	canReadSwap: boolean;
	canWriteSwap: boolean;
	[key: string]: any;
}

type DisplayLedgerCategory = {
	id: string;
	description: string | null;
	calculationType: string;
	name: string;
	type: string;
};

export const TYPE_OF_CALCULATIONS_LABELS = {
	[TypeOfLedgerCalculation.DairyCwt]: 'Per CWT (Dairy)',
	[TypeOfLedgerCalculation.DairyHead]: 'Per Head (Dairy)',
	[TypeOfLedgerCalculation.SwineHead]: 'Per Head (Swine)',
};
export interface swineProductionDetailsRow {
	name: string;
	value: number;
}
export default class BusinessesBusinessBusinessSettingsController extends Controller {
	@service permissions: any;
	settingsRoutePath = 'businesses.business.settings';

	@tracked business: CustomerEntity | undefined = this.model.data?.Customer;

	@tracked organizationId: string | null = null;
	@tracked globalCustomerId: string | null = null;
	@tracked locationId: string | null = null;
	@tracked memberIdToRemove: string | null = null;
	@tracked entityGroupEntryIdToRemove: string | null = null;
	@tracked ledgerCategoryToRemove: LedgerCategory | null = null;
	@tracked memberDataToEdit: Member | null = null;
	@tracked ledgerCategoryDataToEdit: LedgerCategory | null = null;
	@tracked swineProductionDetailsDataToEdit: swineProductionDetailsRow | null = null;

	declare model: ModelFrom<BusinessesBusinessBusinessSettingsRoute>;

	canViewCalculationTypeRoles = ['DairyProducer', 'HogProducer'];
	canViewCategoryRoles = [BusinessEntityRole.DairyProducer, BusinessEntityRole.GrainProducer, BusinessEntityRole.HogProducer];

	canViewMilkCheckCategoryRole = [BusinessEntityRole.DairyProducer];

	get membersColumns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '3bd7c0c6-44f3-4dd9-bbfa-80c126ff6a15',
				name: 'First Name',
				valuePath: 'User.firstName',
				minWidth: 110,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a47e2ea7-cb47-4781-a095-e3a0f552816f',
				name: 'Last Name',
				valuePath: 'User.lastName',
				minWidth: 140,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '7bccdec9-c554-4e23-a38f-3c25e473957f',
				name: 'Email',
				valuePath: 'email',
				minWidth: 215,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b07d50a8-3660-470f-bf28-3b8f5d3c3900',
				name: 'Insurance',
				minWidth: 110,
				width: 148,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '22d9133a-b19e-49cb-9658-d245b665e4f2',
						name: 'Read',
						valuePath: 'canReadInsurance',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						cellComponent: CellComponents.Check,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '2bcb550d-69a5-4f33-9439-25193b47e1ec',
						name: 'Write',
						valuePath: 'canWriteInsurance',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						cellComponent: CellComponents.Check,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '3a64d43e-efb1-4918-9500-4bb5d43a7f06',
				name: 'Operations',
				minWidth: 110,
				width: 148,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '350e3704-a084-490f-88da-326981fed8da',
						name: 'Read',
						valuePath: 'canReadOperations',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						cellComponent: CellComponents.Check,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '27639d61-2a1f-4c0e-bc0d-000d9ee243f3',
						name: 'Write',
						valuePath: 'canWriteOperations',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						cellComponent: CellComponents.Check,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '8bc1a9a0-4920-4b6b-b1db-ae9c6b118364',
				name: 'Brokerage',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '3b9768b1-82c1-4d47-a8a5-3b1cc097f45f',
						name: 'Read',
						valuePath: 'canReadBrokerage',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						cellComponent: CellComponents.Check,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '6cf20350-ed97-4e4c-879f-a105cc409a76',
						name: 'Write',
						valuePath: 'canWriteBrokerage',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						cellComponent: CellComponents.Check,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'd560589c-1a56-492b-89d1-e1670833af7d',
				name: 'Crop / Feed Plans',
				minWidth: 110,
				width: 148,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'b637a328-cb0f-45c3-93d7-c7c92eb92a42',
						name: 'Read',
						valuePath: 'canReadFeedAndCropPlan',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						cellComponent: CellComponents.Check,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '6c832c7c-8b99-4de4-86f8-90912820f6a0',
						name: 'Write',
						valuePath: 'canWriteFeedAndCropPlan',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						cellComponent: CellComponents.Check,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'acec5553-22f3-4310-a850-c3fabdd4bd0b',
				name: 'Swap',
				minWidth: 110,
				width: 148,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'e778baab-1a4d-4784-bea2-d7911ba05e54',
						name: 'Read',
						valuePath: 'canReadSwap',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						cellComponent: CellComponents.Check,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '7d961878-f48e-42b3-aff4-29f6eaa56c3b',
						name: 'Write',
						valuePath: 'canWriteSwap',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						cellComponent: CellComponents.Check,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '356dc45e-9eeb-4a4b-81af-e1fb5c1eec86',
				name: 'Organization',
				minWidth: 110,
				width: 148,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '4d211421-9d4b-44f5-ba31-732c7ff8dcdf',
						name: 'Read',
						valuePath: 'canReadOrganization',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						cellComponent: CellComponents.Check,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '2f8e6d11-e8d2-41a3-9b5c-1f03d38fc569',
						name: 'Write',
						valuePath: 'canWriteOrganization',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						cellComponent: CellComponents.Check,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'e165494e-3fcc-486e-892a-4f073cbdb512',
				name: 'Business',
				minWidth: 110,
				width: 148,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '31d81bad-9407-44ac-ba1f-5195ea63f22a',
						name: 'Read',
						valuePath: 'canReadBusiness',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						cellComponent: CellComponents.Check,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'b2dc2bda-fb79-4bae-b1d0-285bf9d981cc',
						name: 'Write',
						valuePath: 'canWriteBusiness',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						cellComponent: CellComponents.Check,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '0f0cf732-19d4-4691-88d6-59047f1d418e',
				name: 'EntityGroup',
				minWidth: 110,
				width: 148,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '6a939719-6fee-41a0-a783-4ff03fcbc83b',
						name: 'Read',
						valuePath: 'canReadEntityGroup',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						cellComponent: CellComponents.Check,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '7350c3d3-521e-4b37-9824-a4cb07a652ae',
						name: 'Write',
						valuePath: 'canWriteEntityGroup',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						cellComponent: CellComponents.Check,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '09b34425-e6f4-4aed-9061-5e51dd455b94',
				name: '',
				width: 80,
				textAlign: 'center',
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Edit',
					fn: this.editMember,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3a26c9ab-2735-46cb-a54d-cb5efedcab57',
				name: '',
				valuePath: 'id',
				minWidth: 130,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Remove',
					fn: this.removeMember,
				},
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get permissionGroupsColumns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'e8303a88-7432-49dd-bf26-4a81b1761dfc',
				name: 'Name',
				valuePath: 'Group.name',
				minWidth: 130,
				textAlign: 'left',
				headerTextAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1440a796-0bdd-46de-b32b-1e7d4f8fa1ec',
				name: '',
				valuePath: 'id',
				minWidth: 130,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Remove',
					fn: this.removeEntityGroupEntry,
				},
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get hogProductionDetailsColumns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'e8203a89-7632-49ed-bf26-4a81b7766dfc',
				name: 'Name',
				valuePath: 'name',
				minWidth: 130,
				textAlign: '',
				headerTextAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3036161b-5d1f-4c0f-b311-57e3c3ba008f',
				name: 'Value',
				valuePath: 'value',
				minWidth: 130,
				textAlign: '',
				headerTextAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3531e43e-a37e-4dae-anca-cd12bcfq7783',
				name: '',
				valuePath: 'id',
				width: 80,
				maxWidth: 80,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'secondary',
					text: 'Edit',
					fn: this.editSwineProductionDetail,
				},
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get revenueExpenseCategoriesColumns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '95ebab7b-1f2f-4a70-9008-d8e723608edb',
				name: 'Name',
				valuePath: 'name',
				minWidth: 130,
				textAlign: 'left',
				headerTextAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '95e2ab4b-1f2f-4a70-9008-d8v723o98edb',
				name: 'Unit',
				valuePath: 'calculationType',
				minWidth: 80,
				textAlign: 'left',
				headerTextAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3036161b-5d1f-4c0f-b311-57e3c3ba008f',
				name: 'Description',
				valuePath: 'description',
				minWidth: 130,
				textAlign: 'left',
				headerTextAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3581e43e-a97e-4dae-abca-cd12bbff7783',
				name: '',
				valuePath: 'id',
				width: 80,
				maxWidth: 80,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Edit',
					fn: this.editCategory,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3a26c9ab-2735-46cb-a54d-cb5efedcab57',
				name: '',
				valuePath: 'id',
				maxWidth: 110,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Remove',
					fn: this.removeLedgerCategory,
				},
				isFixed: '',
				isVisible: true,
			},
		];

		return this.showCalculationType ? baseColumns : baseColumns.filter((column) => column.id != '95e2ab4b-1f2f-4a70-9008-d8v723o98edb');
	}

	get milkPremiumAndDeductionCategoriesColumns(): TableColumn[] {
		const milkCheckEntries: TableColumn[] = (this.model.data?.LedgerMilkCheckCategories as LedgerMilkCheckCategory[])
			.map((LedgerMilkCheckCategory) => {
				return {
					id: uuid(LedgerMilkCheckCategory.name, 'b8520383-3afc-4498-aa3b-eb5cbd533dc6'),
					name: LedgerMilkCheckCategory.name,
					valuePath: camelize(LedgerMilkCheckCategory.name + ' Forecasted'),
					minWidth: 220,
					textAlign: 'right',
					isSortable: true,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					isFixed: '',
					isVisible: true,
					isTotaled: true,
				};
			})
			.sortBy('name');

		return [
			{
				id: 'f0bbbe46-6233-4159-b13d-7a1f7635a0b9',
				name: 'Month',
				valuePath: 'month',
				minWidth: 150,
				textAlign: 'left',
				isSortable: true,
				isReorderable: false,
				cellComponent: CellComponents.MonthFormat,
				isFixed: '',
				isVisible: true,
			},
			...milkCheckEntries,
			{
				id: '0226df7d-4d87-4b9f-a383-82435f09cb64',
				name: 'Total',
				valuePath: 'forecastedTotal',
				minWidth: 150,
				textAlign: 'right',
				isSortable: false,
				isReorderable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				isFixed: 'right',
				isVisible: true,
				isTotaled: true,
			},
		];
	}

	get showCategoryCards() {
		return this.model.data?.Customer?.businessRoles?.find((role: BusinessEntityRole) => this.canViewCategoryRoles.includes(role));
	}

	get showCalculationType() {
		return this.model.data?.Customer?.businessRoles?.find((role: BusinessEntityRole) => this.canViewCalculationTypeRoles.includes(role));
	}

	get showMilkCheckCategoryCard() {
		return this.model.data?.Customer?.businessRoles?.find((role: BusinessEntityRole) => this.canViewMilkCheckCategoryRole.includes(role));
	}

	get members() {
		return this.model.data?.Customer?.Members?.filter((member: Member) => {
			if (!member.User && !member.userEmail) {
				return false;
			}
			return true;
		}).map((member: Member) => {
			const obj = { ...member };
			obj.email = member.User?.email ?? member.userEmail;
			return obj;
		});
	}

	get hasDairyRole() {
		return (
			this.model.data?.Customer?.businessRoles?.some(
				(role) => role === BusinessEntityRole.DairyProducer || role === BusinessEntityRole.DairyProcessor,
			) ?? false
		);
	}

	get expenseCategories() {
		return this.model.data?.LedgerExpenseCategories.map((category) => {
			return this.getCategory(category);
		});
	}

	get revenueCategories() {
		return this.model.data?.LedgerRevenueCategories.map((category) => {
			return this.getCategory(category);
		});
	}

	get premiumAndDeductionCategories() {
		return this.model.data?.LedgerMilkCheckCategories.map((category) => {
			return this.getCategory(category);
		});
	}

	private getCategory(category: LedgerMilkCheckCategory | LedgerRevenueCategory | LedgerExpenseCategory): DisplayLedgerCategory {
		const displayCategory: DisplayLedgerCategory = {
			id: category.id,
			description: category.description ?? null,
			calculationType:
				category.calculationType == null
					? 'Static'
					: `${TYPE_OF_CALCULATIONS_LABELS[category.calculationType as keyof typeof TYPE_OF_CALCULATIONS_LABELS]}`,
			name: category.name,
			type: category.type,
		};
		return displayCategory;
	}

	get hogProductionDetailsRows() {
		return [
			{
				name: 'Average Finish Age In Weeks',
				value: this.model.data?.Customer.averageFinishAgeInWeeks,
			},
			{
				name: 'Average Finish Weight In Lbs',
				value: this.model.data?.Customer.averageFinishWeightInLbs,
			},
		];
	}

	get createdByUserFullName() {
		const createdByUser = this.model.data?.Customer?.CreatedByUser;
		return generateFullName(createdByUser?.firstName, createdByUser?.lastName) ?? 'null';
	}

	get lastUpdatedByUserFullName() {
		const lastUpdatedByUser = this.model.data?.Customer?.LastUpdatedByUser;
		return generateFullName(lastUpdatedByUser?.firstName, lastUpdatedByUser?.lastName) ?? 'null';
	}

	@action
	removeMember(row: any) {
		this.memberIdToRemove = row.id;
	}

	@action
	editMember(row: any) {
		this.memberDataToEdit = { ...row, currentUserPermissions: { ...this.business?.CurrentUserPermissions } };
	}

	@action
	editCategory(row: LedgerCategory) {
		this.ledgerCategoryDataToEdit = { ...row };
	}

	@action
	editSwineProductionDetail(row: any) {
		this.swineProductionDetailsDataToEdit = { ...row };
	}

	@action
	closeRemoveMemberModal() {
		this.memberIdToRemove = null;
	}

	@action
	removeEntityGroupEntry(row: any) {
		this.entityGroupEntryIdToRemove = row.id;
	}

	@action
	closeRemoveEntityGroupEntryModal() {
		this.entityGroupEntryIdToRemove = null;
	}

	@action
	closeEditMemberModal() {
		this.memberDataToEdit = null;
	}

	@action
	closeEditCategoryModal() {
		this.ledgerCategoryDataToEdit = null;
	}

	@action
	closeEditSwineProdcutionDetailsModal() {
		this.swineProductionDetailsDataToEdit = null;
	}

	@action
	removeLedgerCategory(row: LedgerCategory) {
		this.ledgerCategoryToRemove = row;
	}

	@action
	closeRemoveLedgerCategoryModal() {
		this.ledgerCategoryToRemove = null;
	}
}
