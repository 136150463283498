import { type TrackedAsyncData } from 'ember-async-data';
import { type ErrorRecord } from 'ember-headless-form';

export type ValidationState = TrackedAsyncData<ErrorRecord<object>>;
export type SubmissionState = TrackedAsyncData<boolean>;

export type FormState = {
	submit?: () => void;
	validationState?: ValidationState;
	submissionState?: SubmissionState;
	isDirty?: boolean;
};
