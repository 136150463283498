import { Big, BigSource } from 'big.js';

function formatNumber(value: number): number {
	const bigValue = new Big(value);
	return bigValue.toNumber();
}

/**
 * Subtracts `b` from `a`.
 *  @throws {Error} If inputs cannot be converted to valid numbers
 */
function subtract(a: BigSource, b: BigSource): number {
	const bigA = new Big(a);
	const bigB = new Big(b);
	const result = bigA.minus(bigB);
	return formatNumber(parseFloat(result.toString()));
}

/**
 * Divides `a` by `b`, throws an error if `b` is an invalid divisor
 * @throws {Error} If inputs cannot be converted to valid numbers
 */
function divide(a: BigSource, b: BigSource): number {
	const bigA = new Big(a);
	const bigB = new Big(b);
	return bigA.div(bigB).toNumber();
}

/**
 * Divides `a` by `b`, returns `null` if `b` is an invalid divisor
 * @throws {Error} If inputs cannot be converted to valid numbers
 */
function safeDivideNull(a: BigSource, b: BigSource): number | null {
	if (_isInvalidDivisor(b)) return null;
	return divide(a, b);
}

/**
 * Divides `a` by `b`, returns `0` if `b` is an invalid divisor
 * @throws {Error} If inputs cannot be converted to valid numbers
 */
function safeDivideZero(a: BigSource, b: BigSource): number {
	if (_isInvalidDivisor(b)) return 0;
	return divide(a, b);
}

export { subtract, divide, safeDivideNull, safeDivideZero };

/**
 * Checks if `x` is an invalid divisor (zero or invalid number)
 */
function _isInvalidDivisor(x: BigSource): boolean {
	try {
		return new Big(x).eq(0);
	} catch (e) {
		return true;
	}
}
