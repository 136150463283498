type BusinessRevenueAndExpensesData = {
	businessRevenue: number | null;
	businessExpenses: number | null;
	businessNetPnl: number | null;
	totalRevenue: number | null;
	totalExpenses: number | null;
	totalNetPnl: number | null;
};

export { type BusinessRevenueAndExpensesData };
