import Controller from '@ember/controller';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Big from 'big.js';
import { Task, task } from 'ember-concurrency';
import { DateTime } from 'luxon';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import BusinessesBusinessCropDetailRoute from 'vault-client/routes/businesses/business/crop-detail';
import MarketDataService from 'vault-client/services/market-data';
import {
	Crop,
	Mutation_updateCropArgs,
	Mutation_createPhysicalCropTransactionArgs,
	Mutation_updatePhysicalCropTransactionArgs,
	Mutation_setCropHarvestsArgs,
	CropHarvestSetInput,
	Maybe,
	CropTransaction,
	CropPricingMethodology,
	Future,
	Product,
	PhysicalCropTransaction,
	Mutation_setCropPricesByYearArgs,
	CropHarvest,
} from 'vault-client/types/graphql-types';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import {
	updateCrop,
	setCropHarvests,
	addPhysicalCropTransaction,
	updatePhysicalCropTransaction,
	getTotalBushelsForPricedCropTransactions,
	getAvgPriceForPricedCropTransactions,
	getTotalPriceForPricedCropTransactions,
	getCropMarketPricePerUnit,
	pricingTypeToDisplayString,
	ContractDisplayType,
	getAllCropTransactionsByType,
	getTotalBushelsForCropTransactions,
	getAvgPriceForBasisOnlyCropTransactions,
	getAvgPriceForFlatCropTransactions,
	getAvgPriceForHTACropTransactions,
	setCropPricesByYear,
	formatCurrency,
	formatAmount,
	getCropPriceForHarvestYear,
	VolumeUnit,
	getVolumeUnitBySlug,
	formatGrainVolumeUnit,
} from 'vault-client/utils/grain-utils';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { getSalesTypeDisplayValue } from 'vault-client/utils/vgs-utils';

type SidePanelState = 'EditCrop' | 'ApplyTemplate' | 'AddEditCropHarvest' | 'AddEditCropContract' | 'EditPrice' | null;

export interface ContractRow {
	id: string;
	orderType: ContractDisplayType;
	contractNumber: Maybe<string> | undefined;
	crop: string | undefined;
	units: number;
	futuresMonth: Maybe<string> | undefined;
	futuresPrice: Maybe<number> | undefined;
	basisPrice: Maybe<number> | undefined;
	flatPrice: Maybe<number> | undefined;
	deliveryStartDate: string;
	deliveryEndDate: string;
	location: Maybe<string> | undefined;
	buyer: Maybe<string> | undefined;
}

export interface SidePanelData {
	formId?: string;
	disableSubmitButton?: boolean;
	panelSubmitAction?: (...args: unknown[]) => void | Task<void, unknown[]>;
	submitButtonText: string;
	panelTitle: string;
}

export default class CropDetailController extends Controller {
	@tracked startDate: string = DateTime.now().startOf('year').toISODate();
	@tracked endDate: string = DateTime.now().endOf('year').toISODate();
	@tracked isSidePanelOpen = false;
	@tracked isApplyTemplateFormValid = false;
	@tracked isEditCropForm = false;
	@tracked isApplyTemplateForm = false;
	@service declare marketData: MarketDataService;

	@tracked sidePanelState: SidePanelState = null;
	@tracked applyTemplateFormId = '';
	get cropTransactions() {
		return this.model.cropDetail.data?.CropTransactions ?? [];
	}

	guid = guidFor(this);
	queryParams = ['startDate', 'endDate'];

	fieldsRoutePath = 'businesses.business.fields';

	declare model: ModelFrom<BusinessesBusinessCropDetailRoute>;

	harvestYearOptions = [
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').minus({ year: 2 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').minus({ year: 2 }).toISODate(),
			endDate: DateTime.now().endOf('year').minus({ year: 2 }).toISODate(),
		},
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').minus({ year: 1 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').minus({ year: 1 }).toISODate(),
			endDate: DateTime.now().endOf('year').minus({ year: 1 }).toISODate(),
		},
		{
			displayName: 'Current Harvest Year',
			startDate: DateTime.now().startOf('year').toISODate(),
			endDate: DateTime.now().endOf('year').toISODate(),
		},
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').plus({ year: 1 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').plus({ year: 1 }).toISODate(),
			endDate: DateTime.now().endOf('year').plus({ year: 1 }).toISODate(),
		},
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').plus({ year: 2 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').plus({ year: 2 }).toISODate(),
			endDate: DateTime.now().endOf('year').plus({ year: 2 }).toISODate(),
		},
		{
			displayName: 'Harvest Year '.concat(DateTime.now().startOf('year').plus({ year: 3 }).get('year').toString()),
			startDate: DateTime.now().startOf('year').plus({ year: 3 }).toISODate(),
			endDate: DateTime.now().endOf('year').plus({ year: 3 }).toISODate(),
		},
	];

	get volumeUnit(): VolumeUnit {
		return getVolumeUnitBySlug(this.hedgeProduct?.slug);
	}

	get businessesWithAccess() {
		const businesses = this.model.fetchBusinesses?.data?.Customers ?? [];
		return businesses.filter((business) => business.hasWriteAccess);
	}

	get cropFieldLedgerCategories() {
		return this.model.ledgerCategories.data?.CropFieldLedgerCategories ?? [];
	}

	get ledgerEntries() {
		return this.model.cropDetail.data?.CropLedgerEntriesPerHarvestYear ?? [];
	}

	get CropAndFieldLedgerTotalPerHarvestYears() {
		return this.model.cropDetail.data?.CropAndFieldLedgerTotalPerHarvestYears ?? [];
	}

	get soldVsUnsoldColumns(): TableColumn[] {
		const unsoldColumns: TableColumn[] = [
			{
				id: '31e63f84-7c19-44e8-96a6-31a1923ced88',
				name: formatGrainVolumeUnit(this.volumeUnit),
				valuePath: 'unsoldUnits',
				width: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
		];

		if (this.hedgeProduct) {
			unsoldColumns.push(
				{
					id: 'bef91aa0-d947-4be4-8df0-0ad95411e06e',
					name: 'Futures Month',
					valuePath: 'futuresMonth',
					width: 100,
					cellComponent: CellComponents.MonthFormat,
					textAlign: 'left',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: 'bd499ee8-de11-4a0d-a6c9-364f7e5e6748',
					name: 'Futures Price',
					valuePath: 'future',
					width: 100,
					cellComponent: CellComponents.MarketPriceFormat,
					componentArgs: {
						fractionDigitsPath: 'fractionDigits',
						displayFactorPath: 'displayFactor',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: 'b30eef06-d68d-4fa0-ac51-f5aecd5dbb01',
					name: `${this.basisLabel}`,
					valuePath: 'basis',
					width: 80,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: this.cropPricingMethodology === CropPricingMethodology.CmeUsdBasis ? 'currency' : 'percent',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			);
		}

		unsoldColumns.push(
			{
				id: 'aecc30c7-7224-43fd-9d72-70a78bf009d8',
				name: 'Flat Price',
				valuePath: 'flatPrice',
				width: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b30eef06-d68d-4fa0-ac51-f5aecd5dbb01',
				name: 'Mark to Market',
				valuePath: 'unsoldMarkToMarket',
				width: 200,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		);

		return [
			{
				id: 'a7533c52-2ad3-43c9-bbd5-60e9d7dddf57',
				name: 'Production',
				valuePath: 'production',
				textAlign: 'left',
				width: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					maximumFractionDigits: '0',
				},
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a03adeba-e1a1-4a69-99ef-ba22369a7f9d',
				name: 'Sold',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '3f6695a0-a7cc-43c7-801c-5825b35f6115',
						name: formatGrainVolumeUnit(this.volumeUnit),
						valuePath: 'soldUnits',
						width: 80,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isVisible: true,
						isFixed: '',
					},
					{
						id: '22604829-21c5-448a-9399-9529b5195813',
						name: 'Avg Price',
						valuePath: 'soldUnitsAvgPrice',
						width: 80,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'c7839f21-eb5b-4d29-b827-eb9e530141b9',
						name: 'Physical Sales',
						valuePath: 'soldUnitsTotalPrice',
						width: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '6fe0f339-2628-437a-9ad6-76c86183b7dc',
				name: 'Unsold',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: unsoldColumns,
			},
			{
				id: '0a9ac33b-6f2b-43ae-9401-d58befaece7b',
				name: 'Total Crop Sales',
				valuePath: 'totalCropSales',
				textAlign: 'left',
				width: 90,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
				},
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get basisLabel() {
		switch (this.cropPricingMethodology) {
			case CropPricingMethodology.CmeUsdBasis:
				return 'Basis (USD)';
			case CropPricingMethodology.CmePercentBasis:
				return 'Basis (%)';
			default:
				return 'Basis';
		}
	}

	get cropPricingMethodology() {
		return this.crop?.pricingMethodology;
	}

	get cropPrice() {
		const { crop, harvestYear } = this;
		const cropPrices = crop?.CropPrices ?? [];

		return getCropPriceForHarvestYear(cropPrices, harvestYear)?.price ?? this.crop?.price;
	}

	get forecastedProduction() {
		return this.model.cropDetail.data?.CropHarvestedAndSoldVolumes[0]?.forecastedProductionInBu ?? 0;
	}

	get soldUnitsTotal() {
		return getTotalBushelsForPricedCropTransactions(this.cropTransactions);
	}

	get unsoldUnitsTotal() {
		const unsoldUnits = this.forecastedProduction - this.soldUnitsTotal;
		return unsoldUnits > 0 ? unsoldUnits : 0;
	}

	get unsoldAvgPrice() {
		const { crop, pointValue = 0, lotSize = 0, harvestYearFuturePrice, harvestYear } = this;
		if (!crop) return null;
		return getCropMarketPricePerUnit(crop, pointValue, lotSize, harvestYearFuturePrice, harvestYear);
	}

	get unsoldMarkToMarket() {
		const { crop, unsoldUnitsTotal: unsoldUnits, unsoldAvgPrice: cropPricePerUnit } = this;
		if (!crop || cropPricePerUnit == null) return null;
		return unsoldUnits * cropPricePerUnit;
	}

	get soldUnitsAvgPrice() {
		const { cropPrice, cropPricingMethodology, cropTransactions, pointValue = 0, lotSize = 0, harvestYearFuturePrice } = this;
		if (cropPrice == undefined || cropPricingMethodology === undefined) return null;
		return getAvgPriceForPricedCropTransactions(
			cropTransactions,
			cropPrice,
			cropPricingMethodology,
			pointValue,
			lotSize,
			harvestYearFuturePrice,
		);
	}

	get soldUnitsTotalPrice() {
		const { cropPrice, cropPricingMethodology, cropTransactions, pointValue = 0, lotSize = 0, harvestYearFuturePrice } = this;
		if (cropPrice == undefined || cropPricingMethodology === undefined) return null;
		return getTotalPriceForPricedCropTransactions(
			cropTransactions,
			cropPrice,
			cropPricingMethodology,
			pointValue,
			lotSize,
			harvestYearFuturePrice,
		);
	}

	get soldVsUnsoldRows() {
		const {
			cropPrice,
			cropPricingMethodology,
			soldUnitsTotalPrice,
			unsoldMarkToMarket,
			unsoldAvgPrice: flatPrice,
			forecastedProduction: production,
			soldUnitsTotal: soldUnits,
			soldUnitsAvgPrice,
			unsoldUnitsTotal: unsoldUnits,
			harvestYearFuture,
		} = this;

		const totalCropSales =
			soldUnitsTotalPrice != undefined || unsoldMarkToMarket != undefined ? (soldUnitsTotalPrice ?? 0) + (unsoldMarkToMarket ?? 0) : null;

		const isBasisPricedCrop =
			cropPricingMethodology === CropPricingMethodology.CmeUsdBasis || cropPricingMethodology === CropPricingMethodology.CmePercentBasis;

		return [
			{
				production,
				soldUnits,
				soldUnitsAvgPrice,
				soldUnitsTotalPrice,
				unsoldUnits,
				flatPrice,
				cropPricingMethodology,
				unsoldMarkToMarket,
				totalCropSales,
				...(isBasisPricedCrop && {
					basis: cropPrice,
					future: harvestYearFuture,
					futuresMonth: harvestYearFuture?.displayExpiresAt,
					displayFactor: harvestYearFuture?.SymbolGroup.displayFactor,
					fractionDigits: harvestYearFuture?.SymbolGroup.fractionDigits,
				}),
			},
		];
	}

	get targetsColumns(): TableColumn[] {
		return [
			{
				id: '58db656f-1822-443e-b508-f5e96dc189be',
				name: 'Order Type',
				valuePath: 'orderType',
				textAlign: 'left',
				width: 100,
				cellComponent: CellComponents.String,
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9f9f583c-c84e-42bf-a505-6099ee490ca3',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				textAlign: 'left',
				width: 140,
				cellComponent: CellComponents.String,
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '67dc28f9-6305-4a1c-a3fa-3cdd4f65ee48',
				name: 'Crop',
				valuePath: 'crop',
				textAlign: 'left',
				width: 100,
				cellComponent: CellComponents.String,
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '4831b045-55bc-48fe-ab12-760df35d57c8',
				name: formatGrainVolumeUnit(this.volumeUnit),
				valuePath: 'units',
				textAlign: 'right',
				width: 100,
				cellComponent: CellComponents.String,
				componentArgs: {
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
				},
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3513c0f6-d0f1-4356-8370-32bff634140d',
				name: 'Futures Price',
				valuePath: 'futuresPrice',
				textAlign: 'right',
				width: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
				},
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '7f3bf236-5eb0-48b3-88ca-b7da1195d9e0',
				name: 'Basis',
				valuePath: 'basisPrice',
				textAlign: 'right',
				width: 100,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
				},
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '05927ec8-9ba2-418f-9cb9-d6a9c8dc2623',
				name: 'Flat Price',
				valuePath: 'flatPrice',
				textAlign: 'right',
				width: 100,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
				},
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '681bf6cc-6ed7-4eb1-9638-ce07eb31a7bc',
				name: 'Delivery Start',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: {
					day: 'numeric',
					month: 'numeric',
					year: 'numeric',
				},
				valuePath: 'deliveryStartDate',
				textAlign: 'left',
				width: 150,
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '4e6f5d08-8fc4-45df-b155-88e641280d9d',
				name: 'Delivery End',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: {
					day: 'numeric',
					month: 'numeric',
					year: 'numeric',
				},
				valuePath: 'deliveryEndDate',
				textAlign: 'left',
				width: 150,
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9002f8b5-0146-4bea-92cb-03b1eafa9275',
				name: 'Location',
				valuePath: 'location',
				textAlign: 'left',
				width: 150,
				cellComponent: CellComponents.String,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get targetsRows() {
		return this.model.cropDetail.data?.GrainTargetOrders.map((target) => ({
			orderType: getSalesTypeDisplayValue(target.salesType),
			contractNumber: target.contractNumber,
			crop: this.cropName,
			units: target.quantity,
			futuresPrice: target.futurePrice,
			basisPrice: target.basis,
			flatPrice: target.cropFlatPrice,
			deliveryStartDate: target.deliveryStartDate,
			deliveryEndDate: target.deliveryEndDate,
			location: target.Location?.name,
		}));
	}

	get contractsColumns(): TableColumn[] {
		const contractColumns = [
			{
				id: '11762f55-1eff-453a-96f3-367180920797',
				name: 'Sales Date',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: {
					day: 'numeric',
					month: 'numeric',
					year: 'numeric',
				},
				valuePath: 'salesDate',
				textAlign: 'left',
				width: 130,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '655bac52-ee90-4ec9-ba4d-0ed40d8cf8de',
				name: 'Order Type',
				valuePath: 'orderType',
				textAlign: 'left',
				width: 100,
				cellComponent: CellComponents.String,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e9d73ec9-9ccf-4e19-81ca-8ce299156b56',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				textAlign: 'left',
				width: 150,
				cellComponent: CellComponents.String,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'd0dd8ced-1117-49fe-ae07-6dd0d3b177ce',
				name: 'Crop',
				valuePath: 'crop',
				textAlign: 'left',
				width: 100,
				cellComponent: CellComponents.String,
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '83c30003-7e0e-470a-9020-5d85281ddc98',
				name: formatGrainVolumeUnit(this.volumeUnit),
				valuePath: 'units',
				textAlign: 'right',
				componentArgs: {
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
				},
				width: 90,
				cellComponent: CellComponents.IntlNumberFormat,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '02cdf8ff-8529-460c-96f7-1532ea9f6b50',
				name: 'Flat Price',
				valuePath: 'flatPrice',
				textAlign: 'right',
				width: 100,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
				},
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '4eb9cc01-d0a4-4e01-9264-bfa506810be9',
				name: 'Delivery Start',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: {
					day: 'numeric',
					month: 'numeric',
					year: 'numeric',
				},
				valuePath: 'deliveryStartDate',
				textAlign: 'left',
				width: 130,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2586bedf-02cd-4f71-be30-e52544467928',
				name: 'Delivery End',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: {
					day: 'numeric',
					month: 'numeric',
					year: 'numeric',
				},
				valuePath: 'deliveryEndDate',
				textAlign: 'left',
				width: 130,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0ef8dfe8-e89b-4616-ba15-ae97971ac7b4',
				name: 'Location',
				valuePath: 'location',
				textAlign: 'left',
				width: 90,
				cellComponent: CellComponents.String,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a9b745d8-7c07-4705-8bee-ec18608f1d48',
				name: 'Buyer',
				valuePath: 'buyer',
				textAlign: 'left',
				width: 90,
				cellComponent: CellComponents.String,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			...(this.canWriteOperations && !this.isVgs
				? [
						{
							id: 'c60ee8bd-f479-4015-af53-7a2e5d15abd8',
							name: '',
							valuePath: '',
							textAlign: 'left',
							width: 70,
							cellComponent: CellComponents.Button,
							componentArgs: {
								size: 'xs',
								style: 'outline',
								fn: this.onEditContractButtonClick,
								disableFn: (contract: CropTransaction) => {
									return this.isSidePanelOpen || !contract.isManuallyAdded || !this.canWriteOperations || this.isVgs;
								},
								text: 'Edit',
							},
							isSortable: false,
							isFixed: '',
							isVisible: true,
						},
						{
							id: 'cac8d27d-a088-4aeb-8495-a027d8e1d83f',
							name: '',
							valuePath: '',
							textAlign: 'left',
							width: 90,
							cellComponent: CellComponents.Button,
							componentArgs: {
								size: 'xs',
								style: 'outline',
								text: 'Remove',
								fn: this.onDeleteContractButtonClick,
								disableFn: (contract: CropTransaction) =>
									this.isSidePanelOpen || !contract.isManuallyAdded || !this.canWriteOperations || this.isVgs,
							},
							isSortable: false,
							isFixed: '',
							isVisible: true,
						},
					]
				: []),
		];
		if (this.hedgeProduct) {
			const hedgeProductOnlyColumns = [
				{
					id: '17573097-8c3f-4af4-9ed0-2bd1db4db244',
					name: 'Futures Month',
					valuePath: 'futuresMonth',
					textAlign: 'left',
					width: 130,
					cellComponent: CellComponents.MonthFormat,
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '439e3d09-9ee8-4340-a142-b55e5fa459ae',
					name: 'Futures Price',
					valuePath: 'futuresPrice',
					textAlign: 'right',
					width: 120,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						minimumFractionDigits: '2',
						maximumFractionDigits: '2',
					},
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '439e3d09-9ee8-4340-a142-b55e5fa459ae',
					name: 'Basis',
					valuePath: 'basisPrice',
					textAlign: 'right',
					width: 80,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						minimumFractionDigits: '2',
						maximumFractionDigits: '2',
					},
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			];

			// Insert the new columns at a specific index so they display before buttons if they exist
			const insertIndex = 7;
			contractColumns.splice(insertIndex, 0, ...hedgeProductOnlyColumns);
		}

		return contractColumns;
	}

	get contractsRows(): ContractRow[] {
		return this.cropTransactions.map((transaction: CropTransaction) => {
			return {
				id: transaction.id,
				orderType: pricingTypeToDisplayString[transaction.pricingType],
				contractNumber: transaction.contractIdentifier,
				crop: this.crop?.name,
				units: transaction.bushels,
				futuresMonth: transaction.futuresMonthStartDate,
				futuresPrice: transaction.futuresPrice,
				basisPrice: transaction.basisPrice,
				flatPrice: transaction.flatPrice,
				deliveryStartDate: transaction.deliveryStartDate ?? '',
				deliveryEndDate: transaction.deliveryEndDate,
				location: transaction.location,
				buyer: transaction.buyer,
				isManuallyAdded: transaction.isManuallyAdded,
				salesDate: transaction.salesDate,
			};
		});
	}

	get expectedHarvestColumns(): TableColumn[] {
		return [
			{
				id: '29d3bff2-ef3f-4469-ab16-f4e612550b28',
				name: 'Harvest Month',
				valuePath: 'harvestMonth',
				textAlign: 'left',
				width: 100,
				cellComponent: CellComponents.MonthFormat,
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2a08dd71-42fe-49e8-9b40-aa83def8b58a',
				name: 'Field Name',
				valuePath: 'fieldName',
				textAlign: 'left',
				width: 100,
				cellComponent: CellComponents.String,
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '89620601-7003-4a50-8e06-1487c06ee624',
				name: 'Acres',
				valuePath: 'acres',
				textAlign: 'right',
				width: 100,
				cellComponent: CellComponents.NumericFormat,
				componentArgs: {
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
				},
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1d6659bf-d237-4b97-b587-4b80ceba307b',
				name: 'Production/Acre',
				valuePath: 'yieldPerAcre',
				textAlign: 'right',
				width: 100,
				cellComponent: CellComponents.NumericFormat,
				componentArgs: {
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
				},
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '6bfdd641-e382-4436-8ca3-baf239cbd838',
				name: 'Total',
				valuePath: 'total',
				textAlign: 'right',
				width: 100,
				cellComponent: CellComponents.NumericFormat,
				componentArgs: {
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
				},
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get expectedHarvestsRows() {
		return this.model.cropDetail.data?.CropHarvests.map((cropHarvest: CropHarvest) => {
			const totalProduction = (cropHarvest.yieldPerAcre ?? 0) * (cropHarvest.acres ?? 0);
			return {
				yieldPerAcre: cropHarvest.yieldPerAcre,
				acres: cropHarvest.acres,
				fieldName: cropHarvest.Field?.name ?? 'Additional Acres',
				total: totalProduction,
				harvestMonth: cropHarvest.forecastedHarvestDate,
			};
		});
	}

	get canWriteOperations() {
		return this.model.Customer?.CurrentUserPermissions.canWriteOperations;
	}

	get crop() {
		return this.model.cropDetail.data?.Crop;
	}

	get isVgs() {
		return this.model.Customer.isVgs;
	}

	get cropId() {
		return this.model.cropDetail.data?.Crop?.id ?? '';
	}

	get fields() {
		return this.model.cropDetail.data?.Fields ?? [];
	}

	get businessId() {
		return this.model.businessId ?? ';';
	}

	get currentTimePeriodOption(): UiDateFilterOption {
		return {
			startDate: this.startDate,
			endDate: this.endDate,
		};
	}

	get hedgeProduct(): Product | undefined {
		return this.model.cropDetail.data?.Crop?.Category.HedgeProduct ?? undefined;
	}

	get sidePanelData(): SidePanelData {
		switch (this.sidePanelState) {
			case 'EditCrop':
				return {
					formId: this.editCropFormId,
					disableSubmitButton: this.onSubmitEditCropForm.isRunning || !this.isEditCropFormValid,
					panelSubmitAction: undefined,
					submitButtonText: 'Edit Crop',
					panelTitle: 'Edit Crop',
				};
			case 'AddEditCropHarvest':
				return {
					formId: this.addEditCropHarvestFormId,
					disableSubmitButton: this.onAddEditCropHarvestFormSubmit.isRunning || !this.addEditCropHarvestFormIsValid,
					panelSubmitAction: undefined,
					submitButtonText: 'Apply',
					panelTitle: 'Add/Edit Expected Harvest',
				};
			case 'ApplyTemplate':
				return {
					formId: this.applyTemplateFormId,
					disableSubmitButton: !this.isApplyTemplateFormValid,
					panelSubmitAction: undefined,
					submitButtonText: 'Apply',
					panelTitle: 'Apply Template',
				};
			case 'AddEditCropContract':
				return {
					formId: this.addEditCropContractFormId,
					disableSubmitButton: this.onSubmitAddEditCropContractForm.isRunning || !this.isAddEditCropContractFormValid,
					panelSubmitAction: undefined,
					submitButtonText: this.cropContractToEdit ? 'Edit' : 'Create',
					panelTitle: this.cropContractToEdit ? 'Edit Contract' : 'Add Contract',
				};
			case 'EditPrice':
				return {
					formId: this.editPriceFormId,
					disableSubmitButton: this.onSubmitEditPriceForm.isRunning || !this.isEditPriceFormValid,
					panelSubmitAction: undefined,
					submitButtonText: 'Submit',
					panelTitle: 'Price Settings',
				};
			default:
				return {
					formId: undefined,
					disableSubmitButton: undefined,
					panelSubmitAction: undefined,
					submitButtonText: 'Apply',
					panelTitle: '',
				};
		}
	}
	get currentFutures() {
		return structuredClone(this.model.cropDetail.data?.Crop?.Category.HedgeProduct?.CurrentFutures ?? []).sort((a, b) =>
			a.displayExpiresAt < b.displayExpiresAt ? -1 : 1,
		);
	}

	get harvestYear() {
		return this.model.harvestYear;
	}

	get harvestYearFuture(): Future | null {
		// Prefer ContractMonthInstrument, fallback to MostCurrentFuture
		return (
			this.model.cropDetail.data?.CropHarvestYears[0]?.ContractMonthInstrument ??
			this.model.cropDetail.data?.Crop?.Category.HedgeProduct?.MostCurrentFuture ??
			null
		);
	}

	get harvestYearFuturePrice(): number | null {
		const barchartSymbol = this.harvestYearFuture?.barchartSymbol;
		if (!barchartSymbol) return null;

		return this.marketData.getLatestPrice(barchartSymbol);
	}

	get standardProductLotSpecification() {
		return this.hedgeProduct?.StandardProductLotSpecification;
	}

	get pointValue() {
		return this.standardProductLotSpecification?.pointValue;
	}

	get lotSize() {
		return this.standardProductLotSpecification?.lotSize;
	}

	get totalRevenues() {
		const cropFlatValues = this.model.cropDetail.data?.Crop?.RevenuesForHarvestYear?.totalUsdFromCropFlatValues ?? 0;
		const totalCropPerAcreValues = this.model.cropDetail.data?.Crop?.RevenuesForHarvestYear?.totalUsdFromCropPerAcreValues ?? 0;
		const fieldFlatValues = this.model.cropDetail.data?.Crop?.RevenuesForHarvestYear?.totalUsdFromFieldFlatValues ?? 0;
		const totalFieldPerAcreValues = this.model.cropDetail.data?.Crop?.RevenuesForHarvestYear?.totalUsdFromFieldPerAcreValues ?? 0;
		const unsoldMarkToMarket = this.unsoldMarkToMarket ?? 0;
		const physicalSales = this.soldUnitsTotalPrice ?? 0;

		return cropFlatValues + totalCropPerAcreValues + fieldFlatValues + totalFieldPerAcreValues + unsoldMarkToMarket + physicalSales;
	}

	get totalExpenses() {
		const cropFlatValues = this.model.cropDetail.data?.Crop?.ExpensesForHarvestYear?.totalUsdFromCropFlatValues ?? 0;
		const totalCropPerAcreValues = this.model.cropDetail.data?.Crop?.ExpensesForHarvestYear?.totalUsdFromCropPerAcreValues ?? 0;
		const fieldFlatValues = this.model.cropDetail.data?.Crop?.ExpensesForHarvestYear?.totalUsdFromFieldFlatValues ?? 0;
		const totalFieldPerAcreValues = this.model.cropDetail.data?.Crop?.ExpensesForHarvestYear?.totalUsdFromFieldPerAcreValues ?? 0;

		return cropFlatValues + totalCropPerAcreValues + fieldFlatValues + totalFieldPerAcreValues;
	}

	get widgetValues() {
		const productionAcres = this.totalAcresForHarvests;
		const productionTotal = this.totalProduction;
		const totalRevenues = this.totalRevenues;
		const totalExpenses = this.totalExpenses;

		return {
			productionAcres,
			productionTotal,
			productionPerAcre: productionAcres ? productionTotal / productionAcres : 0,
			revenuesPerAcre: totalRevenues ? totalRevenues / productionAcres : 0,
			revenuesPerBushel: totalRevenues ? totalRevenues / productionTotal : 0,
			totalRevenues: this.totalRevenues,
			expensesPerAcre: totalExpenses ? totalExpenses / productionAcres : 0,
			expensesPerBushel: totalExpenses ? totalExpenses / productionTotal : 0,
			totalExpenses: this.totalExpenses,
			revenuesForHarvestYear: this.model.cropDetail.data?.Crop?.RevenuesForHarvestYear,
			expensesForHarvestYear: this.model.cropDetail.data?.Crop?.ExpensesForHarvestYear,
		};
	}

	get totalProduction() {
		return this.model.cropDetail.data?.CropHarvests.reduce((acc, curr) => acc + curr.yieldPerAcre * curr.acres, 0) ?? 0;
	}

	get totalAcresForHarvests() {
		return this.model.cropDetail.data?.CropHarvests.reduce((acc, curr) => acc + (curr.acres ?? 0), 0) ?? 0;
	}

	get cropName() {
		return this.model.cropDetail.data?.Crop?.name || '';
	}

	get minTradingRange(): string | undefined {
		if (!this.model.minTradingValue) return undefined;

		const displayFactor = this.harvestYearFuture?.SymbolGroup.displayFactor ?? 1;
		return Big(this.model.minTradingValue).times(displayFactor).toFixed(2);
	}

	get maxTradingRange(): string | undefined {
		if (!this.model.maxTradingValue) return undefined;
		const displayFactor = this.harvestYearFuture?.SymbolGroup.displayFactor ?? 1;
		return Big(this.model.maxTradingValue).times(displayFactor).toFixed(2);
	}

	get totalUsageInUnitsOfMeasure() {
		return this.model.cropDetail.data?.CropHarvests?.reduce((acc, curr) => acc + curr.yieldPerAcre * curr.acres, 0) ?? 0;
	}

	get totalSales() {
		return getTotalBushelsForPricedCropTransactions(this.model.cropDetail.data?.CropTransactions ?? []);
	}

	get cropPercentSoldChartData() {
		const chartDataObj: Record<string, { type: string; amount: number; avgPrice?: number; totalRevenue: number }> = {
			Flat: {
				type: 'Flat Contracts',
				amount: 0,
				avgPrice: 0,
				totalRevenue: 0,
			},
			HTA: {
				type: 'HTA Contracts',
				amount: 0,
				avgPrice: 0,
				totalRevenue: 0,
			},
			Basis: {
				type: 'Basis Contracts',
				amount: 0,
				avgPrice: 0,
				totalRevenue: 0,
			},
			'No Price': {
				type: 'No Price Contracts',
				amount: 0,
				totalRevenue: 0,
			},
			'Not Sold': {
				type: 'Not Sold',
				amount: 0,
				avgPrice: 0,
				totalRevenue: 0,
			},
		};

		const {
			cropPrice = 0,
			cropPricingMethodology = CropPricingMethodology.Flat,
			pointValue = 0,
			lotSize = 0,
			cropTransactions,
			harvestYearFuturePrice,
			unsoldUnitsTotal,
			unsoldAvgPrice,
		} = this;

		const flatTransactions = getAllCropTransactionsByType(cropTransactions, 'Flat');
		chartDataObj['Flat'].amount = getTotalBushelsForCropTransactions(flatTransactions);
		chartDataObj['Flat'].avgPrice = getAvgPriceForFlatCropTransactions(flatTransactions);

		const htaTransactions = getAllCropTransactionsByType(cropTransactions, 'HTA');
		chartDataObj['HTA'].amount = getTotalBushelsForCropTransactions(htaTransactions);
		chartDataObj['HTA'].avgPrice = getAvgPriceForHTACropTransactions(htaTransactions, cropPrice, cropPricingMethodology);

		const basisTransactions = getAllCropTransactionsByType(cropTransactions, 'Basis');
		chartDataObj['Basis'].amount = getTotalBushelsForCropTransactions(basisTransactions);
		chartDataObj['Basis'].avgPrice = getAvgPriceForBasisOnlyCropTransactions(
			basisTransactions,
			pointValue,
			lotSize,
			harvestYearFuturePrice,
		);

		const noPriceTransactions = getAllCropTransactionsByType(cropTransactions, 'No Price');
		chartDataObj['No Price'].amount = getTotalBushelsForCropTransactions(noPriceTransactions);

		if (unsoldUnitsTotal > 0) {
			chartDataObj['Not Sold'].avgPrice = unsoldAvgPrice ?? undefined;
			chartDataObj['Not Sold'].amount = unsoldUnitsTotal;
		}

		return Object.values(chartDataObj);
	}

	get contractAnalysisChartData() {
		const flatData: any[] = [];
		const htaData: any[] = [];
		const basisData: any[] = [];

		const flatTransactions = getAllCropTransactionsByType(this.model.cropDetail.data?.CropTransactions ?? [], 'Flat');
		const htaTransactions = getAllCropTransactionsByType(this.model.cropDetail.data?.CropTransactions ?? [], 'HTA');
		const basisTransactions = getAllCropTransactionsByType(this.model.cropDetail.data?.CropTransactions ?? [], 'Basis');

		/* This logic was added to keep the bubbles of the chart from getting too large.  We are going to cap with a multipler of 50 */
		const flatBasisAndHtaTransactions = [...flatTransactions, ...htaTransactions, ...basisTransactions];
		const multiplier = 50;
		let totalVolume = 0;
		flatBasisAndHtaTransactions.forEach((transaction: CropTransaction) => {
			totalVolume += transaction.bushels;
		});
		/* End of logic */

		const isTraded = this.minTradingRange && this.maxTradingRange;

		flatTransactions.forEach((transaction: CropTransaction) => {
			const content = isTraded ? this.buildTooltipEntriesForTraded(transaction) : this.buildTooltipEntriesForNonTraded(transaction);
			const entries: { tooltipEntries: string[]; title: string } = {
				tooltipEntries: content,
				title: this.buildTooltipTitleForFlat(transaction),
			};
			flatData.push([transaction.salesDate, transaction.flatPrice ?? 0, (transaction.bushels / totalVolume) * multiplier, entries]);
		});
		htaTransactions.forEach((transaction: CropTransaction) => {
			const content = isTraded ? this.buildTooltipEntriesForTraded(transaction) : this.buildTooltipEntriesForNonTraded(transaction);
			const entries: { tooltipEntries: string[]; title: string } = {
				tooltipEntries: content,
				title: this.buildTooltipTitleForHTA(transaction),
			};
			htaData.push([
				transaction.salesDate,
				(transaction.futuresPrice ?? 0) + (transaction.basisPrice ?? 0),
				(transaction.bushels / totalVolume) * multiplier,
				entries,
			]);
		});
		basisTransactions.forEach((transaction: CropTransaction) => {
			const content = isTraded ? this.buildTooltipEntriesForTraded(transaction) : this.buildTooltipEntriesForNonTraded(transaction);
			const entries: { tooltipEntries: string[]; title: string } = {
				tooltipEntries: content,
				title: this.buildTooltipTitleForBasis(transaction),
			};
			basisData.push([
				transaction.salesDate,
				getAvgPriceForBasisOnlyCropTransactions([transaction], this.pointValue ?? 0, this.lotSize ?? 0, this.harvestYearFuturePrice) +
					(transaction.basisPrice ?? 0),
				(transaction.bushels / totalVolume) * multiplier,
				entries,
			]);
		});

		/* This needs to be ordered by the size(third value) descending.  This way the smaller contracts will be on top of the larger ones */
		flatData.sort((a, b) => b[2] - a[2]);
		htaData.sort((a, b) => b[2] - a[2]);
		basisData.sort((a, b) => b[2] - a[2]);
		const charts = [
			{
				type: 'bubble',
				label: 'Flat',
				data: flatData,
			},
			{
				type: 'bubble',
				label: 'HTA',
				data: htaData,
			},
			{
				type: 'bubble',
				label: 'Basis',
				data: basisData,
			},
		];

		if (this.minTradingRange && this.maxTradingRange) {
			charts.push({
				type: 'line',
				label: `Trading Range $${this.minTradingRange} - $${this.maxTradingRange}`,
				data: [] as any[],
			});
		}
		return charts;
	}

	buildTooltipEntriesForNonTraded(transaction: CropTransaction): string[] {
		const contractPrice = transaction.flatPrice ?? 0;
		const formattedContractPrice = formatCurrency(contractPrice);
		const contractValue = formatCurrency(contractPrice * transaction.bushels);
		const formattedContractAmount = formatAmount(transaction.bushels);

		const formattedContractDate = Intl.DateTimeFormat('en-US', {}).format(DateTime.fromISO(transaction.salesDate).toJSDate());
		const formattedDeliveryStartDate = Intl.DateTimeFormat('en-US', {}).format(
			DateTime.fromISO(transaction.deliveryStartDate ?? transaction.deliveryEndDate).toJSDate(),
		);
		const formattedDeliveryEndDate = Intl.DateTimeFormat('en-US', {}).format(
			DateTime.fromISO(transaction.deliveryEndDate ?? transaction.deliveryStartDate).toJSDate(),
		);
		const formattedPercentSold = this.calculatePercentSold(transaction);

		const tooltipEntries = [
			`${formattedContractDate} Contract Date`,
			`${formattedDeliveryStartDate} Delivery Start Date`,
			`${formattedDeliveryEndDate} Delivery End Date`,
			`${formattedContractPrice} Contract Price`,
			`${formattedContractAmount} Contract Amount`,
			`${contractValue} Contract Value`,
			`${formattedPercentSold} Sold as of ${formattedContractDate}`,
		];
		return tooltipEntries;
	}

	buildTooltipTitleForFlat(transaction: CropTransaction): string {
		const contractDate = DateTime.fromISO(transaction.salesDate ?? transaction.deliveryStartDate).toFormat('MMM yyyy');
		const title: string = contractDate.concat(' - Flat Contract');
		return title;
	}

	calculatePercentSold(transaction: CropTransaction): string {
		const cropTransactions = this.model.cropDetail.data?.CropTransactions ?? [];
		const soldBefore = cropTransactions
			.filter((t: CropTransaction) => t.salesDate && t.salesDate < transaction.salesDate)
			.reduce((acc: number, curr: CropTransaction) => acc + curr.bushels, 0);
		return Intl.NumberFormat('en-US', {
			style: 'percent',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(soldBefore / this.totalProduction);
	}

	buildTooltipEntriesForTraded(transaction: CropTransaction): string[] {
		const formattedFlatPrice = formatCurrency(transaction.flatPrice ?? 0);
		const formattedFuturesPrice = formatCurrency(transaction.futuresPrice ?? 0);
		const formattedBasisPrice = formatCurrency(transaction.basisPrice ?? 0);
		const contractPrice = (transaction.futuresPrice ?? 0) + (transaction.basisPrice ?? 0);
		const formattedContractPrice = formatCurrency(contractPrice);
		const formattedContractAmount = formatAmount(transaction.bushels);
		const formattedContractDate = Intl.DateTimeFormat('en-US', {}).format(DateTime.fromISO(transaction.salesDate).toJSDate());

		const formattedPercentSold = this.calculatePercentSold(transaction);

		const tooltipEntries = [`${formattedContractDate} Contract Date`];

		if (pricingTypeToDisplayString[transaction.pricingType] === 'Flat') {
			tooltipEntries.push(`${formattedFlatPrice} Flat Price`);
			tooltipEntries.push(`${formatCurrency((transaction.flatPrice ?? 0) * transaction.bushels)} Contract Value`);
		} else if (pricingTypeToDisplayString[transaction.pricingType] === 'Basis') {
			const price =
				getAvgPriceForBasisOnlyCropTransactions([transaction], this.pointValue ?? 0, this.lotSize ?? 0, this.harvestYearFuturePrice) +
				(transaction.basisPrice ?? 0);
			tooltipEntries.push(`${formattedBasisPrice} Basis Price`);
			tooltipEntries.push(`${formatCurrency(price)} Contract Price`);
			tooltipEntries.push(`${formatCurrency(price * transaction.bushels)} Contract Value`);
		} else {
			tooltipEntries.push(`${formattedFuturesPrice} Futures Price`);
			tooltipEntries.push(`${formattedBasisPrice} Basis Price`);
			tooltipEntries.push(`${formattedContractPrice} Contract Price`);
			tooltipEntries.push(`${formatCurrency(contractPrice * transaction.bushels)} Contract Value`);
		}
		tooltipEntries.push(`${formattedContractAmount} Contract Amount`);
		tooltipEntries.push(`${formattedPercentSold} Sold as of ${formattedContractDate}`);
		return tooltipEntries;
	}

	buildTooltipTitleForHTA(transaction: CropTransaction): string {
		const contractDate = DateTime.fromISO(transaction.salesDate ?? transaction.deliveryStartDate).toFormat('MMM yyyy');
		const title: string = contractDate.concat(' - HTA Contract');
		return title;
	}

	buildTooltipTitleForBasis(transaction: CropTransaction): string {
		const contractDate = DateTime.fromISO(transaction.salesDate ?? transaction.deliveryStartDate).toFormat('MMM yyyy');
		const title: string = contractDate.concat(' - Basis Contract');
		return title;
	}

	get monthsForAnalysisChart() {
		const startMonth = DateTime.fromISO(this.startDate).startOf('month').toISODate();
		const endMonth = DateTime.fromISO(this.endDate).minus({ months: 1 }).startOf('month').toISODate();
		return [startMonth, endMonth];
	}

	get formId() {
		if (this.isEditCropForm) {
			return this.editCropFormId;
		}
		if (this.isApplyTemplateForm) {
			return this.applyTemplateFormId;
		}
		return '';
	}

	@action
	getApplyTemplateFormId(formId: string) {
		this.applyTemplateFormId = formId;
	}

	@action
	setTimePeriod(option: UiDateFilterOption) {
		this.startDate = option.startDate;
		this.endDate = option.endDate;
	}

	@action
	openSidePanel(newSidePanelState: SidePanelState) {
		this.sidePanelState = newSidePanelState;
		this.isSidePanelOpen = true;
	}

	@action
	closeSidePanel() {
		this.isSidePanelOpen = false;
		this.sidePanelState = null;
	}

	// Edit Crop
	@tracked
	cropToEdit?: Crop;

	@tracked
	isEditCropFormValid = false;

	editCropFormId = `edit-crop-form-${this.guid}`;

	@action
	openEditCropPanel(crop: Crop) {
		this.cropToEdit = crop;
		this.openSidePanel('EditCrop');
	}

	onSubmitEditCropForm = task({ drop: true }, async (mutationArgs: Mutation_updateCropArgs) => {
		await updateCrop(this, mutationArgs);
		this.closeSidePanel();
	});

	// Add / Edit Crop Contract
	addEditCropContractFormId = `add-edit-contract-${this.guid}`;
	@tracked cropContractToEdit?: CropTransaction;
	@tracked isAddEditCropContractFormValid: boolean = false;
	@tracked addEditCropContractSubmitErrors: string[] = [];

	@action
	onEditContractButtonClick(row: ContractRow) {
		const contractId = row.id;
		const contract = this.cropTransactions.find((contract: CropTransaction) => contract.id === contractId);
		if (contract) {
			this.openAddEditCropContractTray(contract);
		}
	}

	@action
	onAddEditCropContractFormChange(isValid: boolean) {
		this.isAddEditCropContractFormValid = isValid;
	}

	@action
	openAddEditCropContractTray(contract?: CropTransaction) {
		this.addEditCropContractSubmitErrors = [];
		this.isAddEditCropContractFormValid = false;
		this.cropContractToEdit = contract;
		this.openSidePanel('AddEditCropContract');
	}

	@action
	closeAddEditContractTray() {
		this.addEditCropContractSubmitErrors = [];
		this.cropContractToEdit = undefined;
		this.isAddEditCropContractFormValid = false;
		this.closeSidePanel();
	}

	onSubmitAddEditCropContractForm = task(
		{ drop: true },
		async (
			createMutationArgs: Mutation_createPhysicalCropTransactionArgs | undefined,
			updateMutationArgs: Mutation_updatePhysicalCropTransactionArgs | undefined,
		) => {
			const errors: string[] = [];
			try {
				if (createMutationArgs) {
					await addPhysicalCropTransaction(this, createMutationArgs);
				} else if (updateMutationArgs) {
					await updatePhysicalCropTransaction(this, updateMutationArgs);
				}
				this.closeAddEditContractTray();
			} catch (e: unknown) {
				if (typeof e === 'string') {
					errors.push(e);
				} else if (e instanceof Object && 'message' in e && typeof e.message === 'string') {
					errors.push(e.message);
				}
				this.addEditCropContractSubmitErrors = errors;
			}
		},
	);

	// Delete Physical Crop Transaction
	@tracked
	physicalCropTransactionToDelete?: PhysicalCropTransaction;

	deletePhysicalCropTransaction(physicalCropTransaction: PhysicalCropTransaction) {
		this.physicalCropTransactionToDelete = physicalCropTransaction;
	}

	@action
	onDeleteContractButtonClick(row: ContractRow) {
		const contractToDelete = this.cropTransactions.find(({ id }: { id: string }) => id === row.id);
		if (contractToDelete && contractToDelete.isManuallyAdded) {
			// This cast is fairly safe because manually added cropTransactions are always PhysicalCropTransactions
			// We won't be accessing the exclusive properties
			this.deletePhysicalCropTransaction(contractToDelete as unknown as PhysicalCropTransaction);
		}
	}

	@action
	onCloseDeletePhysicalCropTransactionModal() {
		this.physicalCropTransactionToDelete = undefined;
	}

	// Apply Template Form
	@action
	openApplyTemplatePanel() {
		this.openSidePanel('ApplyTemplate');
	}

	// Add Edit Crop Harvest Form
	addEditCropHarvestFormSubmitButtonText = 'Apply';
	addEditCropHarvestFormId = `add-edit-crop-harvests-form-${this.guid}`;

	@tracked addEditCropHarvestFormSubmitErrors: string[] = [];
	@tracked addEditCropHarvestFormIsValid = false;

	resetAddEditCropHarvestForm() {
		this.addEditCropHarvestFormSubmitErrors = [];
		this.addEditCropHarvestFormIsValid = false;
	}

	@action
	openAddEditCropHarvestsPanel() {
		this.resetAddEditCropHarvestForm();
		this.openSidePanel('AddEditCropHarvest');
	}

	@action
	onAddEditCropHarvestChange(isValid: boolean, updatedCropHarvestSetInputs: CropHarvestSetInput[]) {
		this.addEditCropHarvestFormIsValid = isValid && !!updatedCropHarvestSetInputs.length;
	}

	onAddEditCropHarvestFormSubmit = task({ drop: true }, async (setCropHarvestsMutationArgs: Mutation_setCropHarvestsArgs) => {
		const errors: string[] = [];
		try {
			await setCropHarvests(this, setCropHarvestsMutationArgs);
			this.closeSidePanel();
		} catch (e: unknown) {
			if (typeof e === 'string') {
				errors.push(e);
			} else if (e instanceof Object && 'message' in e && typeof e.message === 'string') {
				errors.push(e.message);
			}
		}

		this.addEditCropHarvestFormSubmitErrors = errors;
	});

	// Edit Price
	editPriceFormId = `edit-price-form-${this.guid}`;
	@tracked isEditPriceFormValid = false;
	@tracked editPriceFormSubmitErrors: string[] = [];

	@action
	onEditPriceFormChange([isValid]: [boolean]) {
		this.isEditPriceFormValid = isValid;
	}

	@action
	openEditPriceTray() {
		this.resetEditPriceTray();
		this.openSidePanel('EditPrice');
	}

	resetEditPriceTray() {
		this.isEditPriceFormValid = false;
		this.editPriceFormSubmitErrors = [];
	}

	onSubmitEditPriceForm = task({ drop: true }, async (editPriceArgs: Mutation_setCropPricesByYearArgs) => {
		const errors: string[] = [];
		try {
			await setCropPricesByYear(this, editPriceArgs);
			this.closeSidePanel();
		} catch (e: unknown) {
			if (typeof e === 'string') {
				errors.push(e);
			} else if (e instanceof Object && 'message' in e && typeof e.message === 'string') {
				errors.push(e.message);
			}
		}

		this.editPriceFormSubmitErrors = errors;
	});
}
